
.illustGenerator {
  width: 100%;
  max-width: 40rem;
  margin: 0 auto;
  padding: 1rem;
}

.illustGeneratorSection {
  padding-bottom: 1rem;
}

.illustGeneratorInfoMark {
  text-align: right;
  font-size: 1.1rem;
  font-weight: bold;
}

.illustGeneratorTitle {
  text-align: center;
  font-size: 1.1rem;
  font-weight: bold;
}

.illustGeneratorInfoMarkButton {
  cursor: pointer;
}

.illustGeneratorImage {
  max-height: 400px;
  max-width: 100%;
}

.illustGeneratorButtons {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;
}