.uploadImageSection {
  padding-bottom: 1rem;
}

.uploadImageTitle {
  text-align: center;
  font-size: 1.25rem;
}

.uploadImageImage {
  max-height: 300px; 
  max-width: 100%;
}