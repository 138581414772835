body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

html { 
  font-family: "Inter", "system-ui"; 
  /* overflow: hidden;  */
}

@supports (font-variation-settings: normal) {
  html { font-family: "Inter var", "system-ui"; }
}

.react-transform-wrapper {
  display: grid !important;
  width: 100% !important;
  height: 100% !important;
}

.react-photo-album {
  padding: 8px;
}

.react-photo-album--photo {
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  border-radius: 8px;

  transition: transform 0.25s, visibility 0.25s ease-in;
}

.react-photo-album--photo:hover {
  border: 1px solid var(--border);
  transform: scale(1.03);
}


.icon-button-icon-wrapper svg {
  stroke-width: 1px;
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 224 71.4% 4.1%;

    --card: 0 0% 100%;
    --card-foreground: 224 71.4% 4.1%;
 
    --popover: 0 0% 100%;
    --popover-foreground: 224 71.4% 4.1%;
 
    --primary: 48 100.0% 50.0%;
    --primary-foreground: 210 20% 98%;
 
    --secondary: 220 14.3% 95.9%;
    --secondary-foreground: 220.9 39.3% 11%;
 
    --muted: 220 14.3% 95.9%;
    --muted-foreground: 220 8.9% 46.1%;
 
    --accent: 220 14.3% 95.9%;
    --accent-foreground: 220.9 39.3% 11%;
 
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 20% 98%;

    --border: 220 13% 91%;
    --input: 220 13% 91%;
    --ring: 224 71.4% 4.1%;
 
    --radius: 0.5rem;
  }
 
  [data-theme='dark'] {
    --background: 240 10% 3.9%;
    --foreground: 0 0% 98%;
 
    --card: 224 71.4% 4.1%;
    --card-foreground: 210 20% 98%;
 
    --popover: 240 10% 3.9%;
    --popover-foreground: 0 0% 98%;;
 
    --primary: 48 100.0% 50.0%;
    --primary-foreground: 220.9 39.3% 11%;
 
    --secondary: 240 3.7% 15.9%;
    --secondary-foreground: 0 0% 98%;
 
    --muted: 240 3.7% 15.9%;
    --muted-foreground: 240 5% 64.9%;
 
    --accent: 240 3.7% 15.9%;
    --accent-foreground: 0 0% 98%;
 
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 85.7% 97.3%;
 
    --border: 240 3.7% 15.9%;
    --input: 240 3.7% 15.9%;
    --ring: 240 4.9% 83.9%;
  }
}
 
@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}




html {
  --color-gray-20: rgb(250, 250, 250);
  --color-gray-25: rgb(247, 247, 247);
  --color-gray-50: rgb(237, 237, 237);
  --color-gray-100: rgb(225, 225, 225);
  --color-gray-200: rgb(200, 200, 200);
  --color-gray-300: rgb(175, 175, 175);
  --color-gray-400: rgb(150, 150, 150);
  --color-gray-500: rgb(125, 125, 125);
  --color-gray-600: rgb(100, 100, 100);
  --color-gray-700: rgb(75, 75, 75);
  --color-gray-800: rgb(50, 50, 50);
  --color-gray-900: rgb(25, 25, 25);
  
  --color-primary-50: rgb(253, 224, 200);
  --color-primary-100: rgb(253, 214, 183);
  --color-primary-200: rgb(250, 191, 143);
  --color-primary-400: rgb(223, 159, 41);
  --color-primary-500: rgb(212, 136, 14);
  --color-primary-700: rgb(212, 120, 14);
  --color-primary-200-contrast: rgb(100, 46, 2);
  --color-primary-500-contrast: white;
  
  --color-error-100: rgb(255, 192, 180);
  --color-error-500: rgb(199, 51, 15);
  
  --color-primary-500-bg: rgb(63, 60, 58);
  
  --background-color: rgb(250, 250, 250);

  --space-1: 0.25rem;
  --space-2: 0.5rem;
  --space-4: 1rem;
  --space-6: 1.5rem;
  --space-8: 2rem;
  
  --border-radius-small: 4px;
  --border-radius-medium: 6px;
  
  --shadow-medium: 0 2px 8px rgba(0, 0, 0, 0.2);
  --shadow-small:  0 1px 4px rgba(0, 0, 0, 0.2);
}

/* button {
  padding: 0.25rem 0.5rem;
  font: inherit;
  font-size: 1.1rem;
  border: 0px;
  border-radius: 2px;
  box-shadow: var(--shadow-small);
}

button:hover {
  background-color: var(--color-gray-100);
} */

/* input {
  padding: 0.5rem 0.1rem;
  border: 1px solid var(--color-gray-500);
  border-radius: 2px;
} */

ul {
  margin: 0;
  padding: 0;
}

li {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
  /* color: gray; */
  /* color: var(--color-gray-700); */
  font-weight: bold;
}

a:hover {
  text-decoration: underline;
}

.pageContentContainer {
  min-height: 100vh;
}

.btnBase {
  padding: 0.25rem 0.5rem;
  font: inherit;
  font-size: 1.1rem;
  border: 0px;
  border-radius: 2px;
  box-shadow: var(--shadow-small);
  /* border: 0.5px solid gray; */
}

.btnBase:hover {
  background-color: var(--color-gray-100);
}

.inputBase {
  padding: 0.5rem 0.1rem;
  border: 1px solid var(--color-gray-500);
  border-radius: 2px;
  width: 100%;
}

.subscriptionContent {
  width: 100%;
  max-width: 40rem;
  margin: 0 auto;
}

.loaderPositionCenter {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 0);
}

.iopaintBtnBase {
  padding: 0.25rem 0.5rem;
  font: inherit;
  font-size: 1.1rem;
  border: 0.1px solid gray;
  border-radius: 2px;
  box-shadow: var(--shadow-small);
  /* border: 0.5px solid gray; */
}

.iopaintBtnBase:hover {
  background-color: var(--color-gray-600);
}

.iopaintBtnBase:disabled {
  color: gray;
}