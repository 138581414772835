.illustAboutSection {
  padding-bottom: 1rem;
}

.illustAboutTitle {
  text-align: center;
  font-size: 1.25rem;
  font-weight: bold;
  padding-bottom: 2rem;
}

.illustAboutImageContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;
}

.illustAboutImage {
  /* width: 300px; */
  max-width: 100%;
  max-height: 250px;
}

.illustAboutUpscaleImage {
  /* width: 400px; */
  max-width: 100%;
  max-height: 200px;
}