.notification {
  width: 100%;
  /* height: 3rem; */
  background-color: #1a8ed1;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 5%;
  align-items: center;
  /* color: white; */

  position: fixed;
  background-color: var(--color-gray-50);
  background-color: var(--color-gray-400);
  /* box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25); */
  box-shadow: var(--shadow-medium);
  border: 0.25px solid rgb(208, 208, 208);
  border-radius: 2px;
  /* top: 100px; */
  bottom: 5%;
  z-index: 500;
  /* width: 40%; */
  max-width: 60%;
  left: 0.5%;
  right: 5%;
  opacity: 0.9;
}

.notification h2,
.notification p {
  font-size: 1rem;
  margin: 0;
}

.error {
  /* background-color: #690000; */
  /* opacity: 0.9; */
  color: darkred;
}

.success {
  background-color: #1ad1b9;

  background-color: gray;
  color: white;
  opacity: 0.9;
}

.notificationClear {
  cursor: pointer;
}