.bgRemoveUploadSection {
  padding-bottom: 1rem;
}

.bgRemoveUploadTitle {
  text-align: center;
  font-size: 1.25rem;
}

.bgRemoveUploadImage {
  max-height: 300px; 
  max-width: 100%;
}

.bgRemoveAction {
  text-align: left;
}

.bgRemoveUploadButtons {
  display: flex;
  justify-content: flex-end;
  /* padding: 1rem; */
}