.doImagePromptInputs {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
}

.doImagePromptInputRow {
  padding-bottom: 1rem;
}

.doImagePromptInputImage {
  max-height: 150px;
  max-width: 100%;
  padding-bottom: 1rem;
}

.doImagePromptInputButton {
  text-align: right;
  padding-bottom: 1rem;
  padding-right: 1rem;
}