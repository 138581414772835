* {
  /* box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: sans-serif; */
}

html {
  /* overflow: auto; */
}

html,
body {
  /* height: 100%; */
}

body {
  /* padding: 16px 32px; */
}

body,
#container,
#upload-button {
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
}

h1,
h4 {
  /* text-align: center; */
}

h4 {
  /* margin-top: 0.5rem; */
}

/* #container {
  position: relative;
  width: 720px;
  width: 95%;
  height: 480px;
  max-width: 40rem;
  max-height: 95%;
  max-height: 30%;
  border: 2px dashed #D1D5DB;
  border-radius: 0.75rem;
  overflow: hidden;
  margin-top: 1rem;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;

  margin: 0 auto;
} */

.imageContainer {
  position: relative;
  /* width: 720px; */
  width: 95%;
  /* height: 480px; */
  max-width: 50rem;
  max-height: 95%;
  border: 2px dashed #D1D5DB;
  border-radius: 0.75rem;
  overflow: hidden;
  margin-top: 1rem;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;

  margin: 0 auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.oriImageContainer {
  position: relative;
  /* width: 720px; */
  width: 95%;
  /* height: 480px; */
  max-width: 50rem;
  max-height: 95%;
  /* border: 2px dashed #D1D5DB; */
  /* border-radius: 0.75rem; */
  overflow: hidden;
  margin-top: 1rem;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;

  margin: 0 auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#upload-button {
  gap: 0.4rem;
  font-size: 18px;
  cursor: pointer;
}

#upload {
  display: none;
}

svg {
  pointer-events: none;
}

#example {
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
}

#example:hover {
  color: #2563EB;
}

canvas {
  position: absolute;
  width: 100%;
  height: 100%;
}

#status {
  min-height: 16px;
  margin: 8px 0;
}

.bgRemoveSection {
  padding-bottom: 1rem;
  text-align: center;
}

.bgRemoveTitle {
  text-align: center;
  padding-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: bold;
}

.bgRemoveControl {
  padding: 1rem;
}

.bgRemoveActionButtons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}