.bgRemove {
  width: 99vw;
  /* padding: 1px; */
  text-align: center;
}

.bgRemoveReset {
  text-align: right;
  padding-right: 1rem;
  padding-bottom: 1rem;
}