.modal {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  border: 0.1px solid gray;
  /* background-color: white; */
  background-color: var(--color-gray-900);
  padding: 1rem;
  /* text-align: center; */
  /* width: 30rem; */
  /* width: 80%; */
  /* max-width: 40rem; */
  max-height: 85vh;
  left: 5%;
  right: 5%;
  z-index: 100;
  position: fixed;
  top: 5vh;

  overflow: auto;
  /* left: calc(50% - 15rem); */
}

.modalContent {
  margin-bottom: 2rem;
}

.modalConfirmButton {
  font: inherit;
  cursor: pointer;
  background-color: #77002e;
  color: white;
  padding: 0.5rem 1.5rem;
  border: 1px solid #77002e;
  border-radius: 4px;
  font-weight: bold;
  margin-top: 2rem;
}

.modalConfirmButton:disabled {
  background-color: gray;
  border-color: lightgray;
}

.modalCancelButton {
  font: inherit;
  cursor: pointer;
  background-color: white;
  /* color: white; */
  padding: 0.5rem 1.5rem;
  border: 1px solid;
  border-radius: 4px;
  font-weight: bold;
  margin-top: 1rem;
  margin-right: 2rem;
}

.modalCancelButton:disabled {
  background-color: gray;
  border-color: lightgray;
}

.modalLoader {
  text-align: center;
}

.resultMessage {
  font-size: 1.25rem;
  font-weight: bold;
  margin-top: 1rem;
}

.modalCloseMark {
  text-align: right;
}
.modalCloseMarkButton {
  cursor: pointer;
  font-size: 1.5rem;
}


@media (min-width: 768px) {
  .modal {
    width: 40rem;
    /* left: 15%;
    right: 15%; */
    left: calc(50% - 20rem);
    right: calc(50% - 20rem);
  }
}