.inpaintFilesSection {
  padding-bottom: 1rem;
}

.inpaintFilesImage {
  max-height: 200px;
  max-width: 100%;
}

.inpaintFilesButtons {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;
  
}
